export const popularDirectionsMixin = {
    data() {
        return {
            loading: false,
            form: {
                parent_id: null,
                title: null,
                preview_title: null,
                attractions_title: null,
                search_title: null,
                slug: null,
                detail_image: null,
                detail_image_delete: null,
                preview_image_square: null,
                preview_image_square_delete: null,
                preview_image_hr_rectangle: null,
                preview_image_hr_rectangle_delete: null,
                preview_image_vr_rectangle: null,
                preview_image_vr_rectangle_delete: null,
                description: null,
                published: false,
                sale_visible: false,
                order: 0,
                attractions: [],
                seo: this.getDefaultSeo(),
            },
            attractionOptions: [],
            parentOptions: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },
    },
};
